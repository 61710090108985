@import './variables';
@import './functions';

$gutter: rem(20px);
$columnsAmount: 12;

.flex-grid {
  display: flex;
  margin: -$gutter;

  .column {
    flex-direction: column;
  }

  @media (max-width: $breakpoint-1440) {
    &.md-column {
      flex-direction: column;
    }

    &.md-row {
      flex-direction: row;
    }
  }

  @media (max-width: $breakpoint-1024) {
    .flex-grid {
      display: block;
    }
  }
}

@for $i from 1 to $columnsAmount {
  .offset-#{$i} {
    margin-left: percentage($i / $columnsAmount);

    @media (max-width: $breakpoint-1024) {
      margin-left: $gutter;
    }
  }

  .col-#{$i} {
    padding: $gutter;
    flex: $i;
    max-width: 100%;

    .no-vertical-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    .no-top-padding {
      padding-top: 0;
    }

    @media (max-width: $breakpoint-1440) {
      &.md {
        flex: 1;
        padding-bottom: 0;

        + .md {
          padding-bottom: $gutter;
        }
      }

      &.md-hidden {
        display: none;
      }
    }
  }
}
