@import 'variables-typography';

.prominentText {
  color: $prominentText;
}

.primaryText {
  color: $primaryText;
}

.secondaryText {
  color: $secondaryText;
}

.normalText {
  font-weight: $normalTextWeight;
}

.mediumText {
  font-weight: $mediumTextWeight;
}

.boldText {
  font-weight: $boldTextWeight;
}

.mediumLineHeight {
  line-height: 1.4em;
}
