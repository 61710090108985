@import '../../../assets/stylesheets/functions.scss';

.spinner {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  position: absolute !important;
  width: auto !important;
  height: auto !important;
  z-index: z-index(dropdown) - 1 !important;
}

$extraVerticalMargin: 8px;
.spinnerContainer {
  position: relative;
}

.spinnerContainerSize {
  &24px {
    min-height: 24px + $extraVerticalMargin;
  }
  &50px {
    min-height: 50px + $extraVerticalMargin;
  }
  &12px {
    min-height: 12px + $extraVerticalMargin;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($white, 0.75);
  opacity: 0;
}

.overlayDelayedFadeIn :global {
  animation: fadeIn 250ms ease-in-out 750ms both;
}

.instantFadeIn :global {
  animation: fadeIn 250ms ease-in-out 0ms both;
}
