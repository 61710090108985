@import '../../../assets/stylesheets/variables';
@import '../../../assets/stylesheets/functions';
@import './variables';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  min-height: 100%;
  height: 100%;
}

.sidebar {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index(drawer);
}

.sidebarNormal {
  flex: 0 0 $sidebarNormalWidth;
  max-width: $sidebarNormalWidth;
}

.sidebarWide {
  flex: 0 0 $sidebarWideWidth;
  max-width: $sidebarWideWidth;
}

.content {
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
}

.contentNormal {
  padding-left: $sidebarNormalWidth;
}

.contentWide {
  padding-left: $sidebarWideWidth;
}

.spacedContent {
  margin: rem(24px) rem(40px);
}

.spacedBottom {
  padding-bottom: 20px;
}

.fullHeight {
  height: 100%;

  &.spacedContent {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: rem(24px);
    padding-bottom: rem(40px);
  }
}
