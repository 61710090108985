@import '../../../assets/stylesheets/mixins';

.buttonLikeLink {
  @include reset-button();
}

.underLineStaticVisible {
  text-decoration: underline;
}

.underLineStaticHidden {
  text-decoration: none;
}

.underLineShowOnHover {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.underLineHideOnHover {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
