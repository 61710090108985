@import '../../../assets/stylesheets/variables.scss';
@import '../../../assets/stylesheets/mixins.scss';
@import '../../../assets/stylesheets/functions.scss';

.container {
  align-items: center;
  background-color: $ebony;
  background-image: url('../../../assets/images/login-bg.png');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
}

.wrapper {
  background-color: $white;
  border-radius: 28px;
  box-shadow: 0px 1px 9px rgba($black, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 640px;
  overflow: hidden;
}

.content {
  align-items: center;
  display: flex;
  flex: 1 1 60%;
  justify-content: center;
  padding: 20px;
}

.photo {
  flex: 1 1 40%;
  background-color: $apricot;
  background-position: center;
  background-size: cover;
  min-height: 400px;
  height: 100%;
  background-image: url('../../../assets/images/login-form-bg.png');
}

.footer {
  margin-top: rem(10px);
  min-width: 640px;
  display: flex;
  flex-direction: row;
  font-size: rem(12px);
  color: $white;
  justify-content: space-between;
}

.footer {
  a:visited,
  a:link {
    color: $white;
  }
}

.tipserByBoltIcon {
  height: rem(16px);
}
