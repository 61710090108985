@import '../../../assets/stylesheets/variables.scss';

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  flex: 1;
  position: relative;
}

.inline {
  display: inline-block;
}

$size12pxTransformScale: 12px / 50px;
.size12px {
  width: 12px;
  height: 12px;
  transform: scale(#{$size12pxTransformScale});
  top: -2px;
}

$size24pxTransformScale: 24px / 50px;
.size24px {
  width: 24px;
  height: 24px;
  transform: scale(#{$size24pxTransformScale});
  top: -2px;
}

.containerDelayedFadeIn :global {
  animation: fadeIn 250ms ease-in-out 750ms both;
}

.containerFadeIn :global {
  animation: fadeIn 250ms ease-in-out 0ms both;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($white, 0.75);
}

.spinner {
  height: 50px;
  width: 50px;
  border: 8px solid lighten($primary, 70%);
  border-radius: 50%;
  position: relative;

  &:before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: $primary;
    position: absolute;
    top: -7px;
    left: 14px;
  }
}
