@import 'variables';

$prominentText: $martinique;
$primaryText: $emperor;
$secondaryText: $dusty-gray;
$blackText: $code-black;
$errorText: $danger;

$normalTextWeight: 400;
$mediumTextWeight: 500;
$boldTextWeight: 800;
