@import './variables.scss';

@mixin for-size($range) {
  @if $range == 1024 {
    @media (min-width: $breakpoint-1024) {
      @content;
    }
  } @else if $range == 1200 {
    @media (min-width: $breakpoint-1200) {
      @content;
    }
  } @else if $range == 1440 {
    @media (min-width: $breakpoint-1440) {
      @content;
    }
  } @else if $range == 1920 {
    @media (min-width: $breakpoint-1920) {
      @content;
    }
  } @else if $range == 2560 {
    @media (min-width: $breakpoint-2560) {
      @content;
    }
  }
}

@mixin reset-list($color) {
  li {
    text-decoration: none;
    list-style-type: none;

    a {
      color: $color;
      text-decoration: none;
    }
  }
}

@mixin reset-button {
  background-color: inherit;
  color: inherit;
  border: none;
  cursor: pointer;
  line-height: 1;
  margin: 0;
  padding: 0;
  outline: 0;

  &:active {
    color: inherit;
  }
}

@mixin reset-link {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

%ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin apply-gray-overlay {
  position: relative;

  &:before {
    position: absolute;
    background: $black;
    opacity: 0.05;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
}

@mixin apply-gray-overlay-after {
  position: relative;

  &:after {
    position: absolute;
    background: $black;
    opacity: 0.05;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
}
