@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.woff2') format('woff2'), url('../fonts/Gotham-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Bold.woff2') format('woff2'), url('../fonts/Gotham-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Medium.woff2') format('woff2'), url('../fonts/Gotham-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book-Italic.woff2') format('woff2'),
    url('../fonts/Gotham-Book-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Light.woff2') format('woff2'), url('../fonts/Gotham-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
