@import '../../../assets/stylesheets/variables.scss';
@import '../../../assets/stylesheets/functions.scss';

.overlay {
  align-items: center;
  background-color: rgba($black, 0.16);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: z-index(modal);
}

.modal {
  background-color: $white;
  border-radius: 40px;
  box-shadow: 0px 3px 30px rgba($black, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  padding: 5rem 9rem;
  position: relative;
}

.small {
  width: 480px;
}

.medium {
  width: 769px;
}

.large {
  width: 1200px;
}

.body {
  display: flex;
  justify-content: center;
}

.header {
  margin-right: 50px;
  padding: 1rem 1rem 0;
  position: relative;

  > h2 {
    margin: 0;
  }
}

.body,
.footer {
  padding: 1rem;
  position: relative;
}

.topBar {
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 1;
}

.bodyOverflowHidden {
  overflow: hidden;
}
