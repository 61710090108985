// Color names generated by http://chir.ag/projects/name-that-color/

// Sizes
$base_fontsize: 14px;
$max-container-width-large: 1260px;
$max-container-width-medium: 900px;
$max-container-width-small: 550px;

// Breakpoints
$breakpoint-768: 769px;
$breakpoint-1024: 1025px;
$breakpoint-1200: 1201px;
$breakpoint-1440: 1441px;
$breakpoint-1920: 1921px;
$breakpoint-2560: 2561px;

// Z-INDEXES
$z-index: (
  content: 0,
  dropdown: 100,
  spinner: 200,
  drawer: 300,
  modal: 400,
  tooltip: 500,
);

// Color Buttons:
$black: #000000;
$code-black: #191919;
$brown: #964b00;
$beige: #e1c699;
$white: #fff;
$blue: #0066cc;
$petrol: #005f6a;
$turquoise: #40e0d0;
$green: #008000;
$olive: #808000;
$yellow: #ffea00;
$orange: #ff8000;
$red: #ff0000;
$pink: #e75480;
$snuff: #ded6e8;
$purple: #800080;
$gold: #bea867;
$multi_color: linear-gradient(to bottom right, $pink, $turquoise, $yellow);

// Color variables
$chestnut-rose: #cb5653;
$apricot: #ec877b;
$silver_tree: #63ae85;
$paradiso: #286776;
$martinique: #303248;
$gun_powder: #44465a;
$waterloo: #848595;
$manatee: #8c88aa;
$moody_blue: #877cd3;
$snuff: #e7e2ed;
$light-blue: #5075ba;
$sandy-brown: #f5a45b;
$dark-brown: #755841;
$terracota: #e06859;
$trout: #525860;
$lavender-gray: #c5b9d3;
$mine-shaft: #222222;
$moody-blue: #877cd3;
$blue-ribbon: #006cff;
$selago: #e9f2fc;
$ebony: #020306;
$cornflower-blue: #5d95fd;
$ocean-green: #49b082;

// Grays color variables - ordered by strength
$abbey: #4f5058;
$emperor: #545454;
$gray: #868686;
$dove-gray: #6e6e6e;
$dusty-gray: #9a9a9a;
$silver-chalice: #9f9f9f;
$spun-pearl: #aaa9ad;
$silver: #c6c6c6;
$alto: #d9d9d9;
$mercury: #e5e5e5;
$gallery: #efefef;
$wild-sand: #f6f6f6;
$alabaster: #fafafa;
$athens-gray: #f2f3f7;
$ebony: #020306;

// Theme colors
$primary: $martinique;
$secondary: $manatee;
$tertiary: $apricot;
$danger: $apricot;

$radius: 1rem;

$gray-background: $gallery;

// UI Defaults
$default-outline: #5075ba;

$instant-transition-speed: 0.1s;
$simple-transition-speed: 0.25s;
$complex-transition-speed: 0.34s;
