@import '../../../assets/stylesheets/variables';
@import '../../../assets/stylesheets/mixins';
@import '../../../assets/stylesheets/functions';
@import './variables.scss';

.redirectBarContainer {
  align-items: center;
  background-color: $moody_blue;
  display: flex;
  min-height: $redirectBarHeight;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: z-index(dropdown);
  padding: 5px 0;
}

.redirectBarContent {
  align-items: center;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  font-size: rem(12px);
  font-weight: 400;
  height: 100%;
  justify-content: flex-start;
  line-height: 1.6;
  padding: 0 rem(60px) 0 rem(40px);
  position: relative;
  width: 100%;

  a {
    color: $white;
  }
}

.closeButton {
  position: absolute;
  right: 20px;
  transition: transform ease $instant-transition-speed;
  transform: scale(1);
  &:hover {
    transform: scale(1.5);
  }
  svg {
    height: 12px;
  }
}
