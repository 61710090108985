.page404Container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    margin-bottom: 4em;
  }

  > a {
    align-self: flex-start;
  }
}

.photo {
  background-color: #ffbdb3;
  background-image: none;
  position: relative;
}

.photo:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/looking.gif');
  background-position: center;
  background-size: cover;
  filter: hue-rotate(160deg);
}

.travolta {
  object-fit: contain;
  object-position: bottom center;
  bottom: 0;
  right: 0;
  height: 35vh;
  position: absolute;
}
