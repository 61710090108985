.modalContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 120%;
}

.buttons {
  position: absolute;
  display: flex;
  max-width: 100%;
  bottom: 0;
  right: 0;

  button {
    margin-left: 10px;
  }
}

.content {
  height: 100px;
  line-height: 1.5em;
}

.contentLoading {
  visibility: hidden;
}
