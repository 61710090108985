@import './fonts.scss';
@import './variables.scss';

html,
body,
#root {
  height: 100%;
}

html {
  font-size: $base_fontsize;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 960px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100%;
  height: auto;
}

//use like <div className="p" (...) to have div behave like p, and still be able to nest another divs in it
.p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
