@import '../../../assets/stylesheets/variables';

.authLinkContainer {
  color: $manatee;
  text-align: left;
  text-decoration: none;
  font-size: 12px;
  border-bottom: 1px solid transparent;
  transition: border ease 200ms;

  &:hover {
    border-bottom: 1px solid $manatee;
  }
}
